define(['app', 'siteObj'], function(app, siteObj) {

  var productAlternatives = function() {

    var component = this;

    component.config = {
      url: siteObj.siteNonSecureURL,
      currency: siteObj.currencyType,
      locale: siteObj.customerLocale || 'en_GB',
      productId: siteObj.productID
    };

    component.init = function(element) {

      component.element = element;
      component.fadeElement = app.element.closest('[data-js-productAlternatives-fade]', component.element).element;
      component.fetchProductAlternatives();

      return component;
    };

    component.successCallback = function(result) {

      if (typeof result !== 'undefined' && result.length > 0) {
        component.initProductPageOOSOpacity();
        component.element.insertAdjacentHTML('afterBegin', result);
      }
    };

    component.failCallback = function() {
      console.warn('failed to fetch alternatives');
    };

    component.fetchProductAlternatives = function() {
      app.ajax.get({
        url: component.config.url + component.config.locale + '/' + component.config.currency + '/' + component.config.productId + '/alternatives.awesome',
        success: function(result) {
          component.successCallback(result);
        },
        failure: component.failCallback

      });
    };

    component.checkScroll = function(value) {
      if (value > 200) {
        app.element.addClass('productAlternatives-fadeOut', component.fadeElement);
        component.removeFadeScroll();
      }
    };

    component.scrollEvent = function (){
      component.checkScroll(window.pageYOffset);
    };

    component.removeFadeScroll = function(){
      window.removeEventListener('scroll', component.scrollEvent, false);
    };

    component.attachFadeScroll = function() {
      window.addEventListener('scroll', component.scrollEvent, false);
    };

    component.initProductPageOOSOpacity = function() {
      app.element.addClass('productAlternatives-fadeIn', component.fadeElement);
      component.attachFadeScroll();
    };

  };

  return productAlternatives;
});
